import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Lister" />
    <div className="longtext">
      <h2>Lister</h2>
      <ul>
        <li>
          <Link to="/help/list-foundations">
            Lister, underlister og seksjoner
          </Link>
        </li>
        <li>
          <Link to="/help/shared-lists">Delte lister</Link>
        </li>
        <li>
          <Link to="/help/delegate-tasks">Deleger oppgaver</Link>
        </li>
        <li>
          <Link to="/help/reviews">Rutiner</Link>
        </li>
        <li>
          <Link to="/help/checklists">Sjekklister</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
